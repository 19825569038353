import { useState } from "react";
import { Link } from "react-router-dom";

import { Box, Container, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import "./Header.scss";

function Header() {
  const [active, setActive] = useState(false);

  const menus = [
    { id: "finance", label: "Finance", link: "/finance" },
    { id: "sports", label: "Sports", link: "/sports" },
    { id: "commerce", label: "Commerce", link: "/commerce" },
    { id: "education", label: "Education", link: "/education" },
    { id: "health", label: "Health", link: "/health" },
    { id: "non_profit", label: "Non-profit", link: "/non-profit" },
  ];

  return (
    <Box component={"section"} id="header">
      <Container maxWidth="false" className="header-holder">
        <Box className="logo-holder">
          <Typography className="logo-name">Tony Tripulca</Typography>
        </Box>
        <IconButton
          className="burger-button"
          onClick={() => setActive(!active)}
        >
          {active ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
        <Box className={`links-holder ${active ? "active" : ""}`}>
          {menus.map((i, iKey) => (
            <Typography key={iKey} component={Link} to={i.link}>
              {i.label}
            </Typography>
          ))}
        </Box>
      </Container>
    </Box>
  );
}

export default Header;
