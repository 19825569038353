import { Navigate, Route, Routes } from "react-router-dom";

import Homepage from "./modules/homepage/Homepage";

function AppRouter() {
  return (
    <Routes>
      <Route exact path="/finance" element={<Homepage />} />
      <Route exact path="/sports" element={<Homepage />} />
      <Route exact path="/commerce" element={<Homepage />} />
      <Route exact path="/education" element={<Homepage />} />
      <Route exact path="/health" element={<Homepage />} />
      <Route exact path="/non-profit" element={<Homepage />} />

      {/* Fallback */}
      <Route exact path="*" element={<Navigate to="/finance" />} />
    </Routes>
  );
}

export default AppRouter;
