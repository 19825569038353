import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  Alert,
  Box,
  Container,
  Grid,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";

import "./Homepage.scss";

import Bambams from "../../assets/bambams.png";
import DiehardScarves from "../../assets/diehardscarves.png";
import DiehardScarvesPortal from "../../assets/diehardscarves-portal.png";
import AxaPh from "../../assets/axa.png";
import MhcDigital from "../../assets/mhc.png";
import Tishabet from "../../assets/tishabet.png";
import VtsCare from "../../assets/vts.png";
import VtsCareAdmin from "../../assets/vts-admin.png";
import Rmc from "../../assets/rmc.png";
import RmcAdmin from "../../assets/rmc-admin.png";
import BiyayaPh from "../../assets/biyaya-ph.png";
import BiyayaPhMusic from "../../assets/biyaya-ph-music-clinic.png";

function Homepage() {
  let pageName = window.location.pathname.substring(1).toUpperCase();

  const [snackbar, setSnackbar] = useState({
    x: "right",
    y: "top",
    open: false,
    duration: 6000,
    text: "",
    severity: "succes",
  });

  let projects = [
    {
      id: "bambams",
      name: "Bambams",
      description:
        'A leading supplier in the promotional products industry. Offering "Products That Excite!", we continually bring new products to the Promotional Products industry, providing fun and original ideas to help our distributors succeed.',
      website: "https://www.bambams.com",
      thumbnail: Bambams,
      group: "commerce",
      down: false,
    },
    {
      id: "diehard_scarves",
      name: "Diehard Scarves",
      description:
        "We design custom scarves, custom headwear, custom socks, custom pins, and apparel for all kinds of teams, brands, and organizations worldwide.",
      website: "https://diehardscarves-uat.netlify.app",
      thumbnail: DiehardScarves,
      group: "commerce",
      down: false,
      maintenance: "",
    },
    {
      id: "diehard_scarves_portal",
      name: "Diehard Scarves Portal",
      description: "Admin and customer portal for Diehard Customs",
      website: "https://diehardscarves-customer.netlify.app/login",
      thumbnail: DiehardScarvesPortal,
      group: "commerce",
      down: false,
      maintenance: "",
    },
    {
      id: "axa",
      name: "Axa Philippines",
      description:
        "AXA Philippines is now a provider of a comprehensive suite of products, personal and group insurance in the Philippines, covering life insurance and investment-linked insurance products, savings and investment, health coverage, and property and casualty insurance.",
      website: "https://www.axa.com.ph",
      thumbnail: AxaPh,
      group: "finance",
      down: false,
      maintenance: "",
    },
    {
      id: "mhc_digital",
      name: "MHC Digital",
      description:
        "MHC Digital Group is an unprecedented digital assets platform, built for purpose and ready to disrupt traditional financial markets.",
      website: "https://mhcdigitalgroup.com",
      thumbnail: MhcDigital,
      group: "finance",
      down: false,
      maintenance: "",
    },
    {
      id: "vts_care",
      name: "VTS Care Clinic",
      description:
        "Virtual Telehealth Clinic (VTC) provide Diagnosis, treatment and management to clients using our state of the art AMD Telemedicine at a distance.",
      website: "https://vts.care/site",
      thumbnail: VtsCare,
      group: "health",
      down: true,
      maintenance: "DNS management maintenance",
    },
    {
      id: "vts_care_admin",
      name: "VTS Care Clinic Admin",
      description:
        "Virtual Telehealth Clinic (VTC) provide Diagnosis, treatment and management to clients using our state of the art AMD Telemedicine at a distance.",
      website: "https://vts.care/client/gov/login",
      thumbnail: VtsCareAdmin,
      group: "health",
      down: true,
      maintenance: "DNS management maintenance",
    },
    {
      id: "rmc",
      name: "Regis Marie College",
      description:
        "Regis Marie College commit to produce students who have Character, Academic Excellence, Scientific Mind and an Achiever through reachable quality education that transforms students into persons of conscience, competence, and compassion.",
      website: "http://rmc.nadescrib.com",
      thumbnail: Rmc,
      group: "education",
      down: false,
      maintenance: "",
    },
    {
      id: "rmc",
      name: "RMC - Admin",
      description:
        "Regis Marie College commit to produce students who have Character, Academic Excellence, Scientific Mind and an Achiever through reachable quality education that transforms students into persons of conscience, competence, and compassion.",
      website: "https://rmc-enrollment-uat.netlify.app/dashboard",
      thumbnail: RmcAdmin,
      group: "education",
      down: true,
      maintenance: "On going maintenance",
    },
    {
      id: "tishabet",
      name: "Tishabet",
      description:
        "An airsoft site located inside Tishabet Field in Imus, Cavite, just right after the exiting CAVITEX. Which is very close and accessible to Metro Manila. The site is being managed by community friendly admins.",
      website: "https://tishabet.com",
      thumbnail: Tishabet,
      group: "sports",
      down: false,
      maintenance: "",
    },
    {
      id: "biyaya_ph",
      name: "Biyaya PH Website",
      description:
        "G.R.A.C.E. Christian Fellwoship soon to be website for reaching out people through the grace of our Lord Jesus.",
      website: "https://biyayaph.com",
      thumbnail: BiyayaPh,
      group: "non-profit",
      down: false,
      maintenance: "",
    },
    {
      id: "biyaya_ph_music",
      name: "Biyaya PH Music Clinic",
      description:
        "G.R.A.C.E. Christian Fellowhip free music clinic as part of its ministry to the people.",
      website: "https://biyayaph.com/music-clinic",
      thumbnail: BiyayaPhMusic,
      group: "non-profit",
      down: false,
      maintenance: "",
    },
  ];

  return (
    <React.Fragment>
      <Box component={"section"} id="homepage-banner">
        <Typography className="page-name">
          {pageName === "" ? "HOME" : pageName}
        </Typography>
      </Box>
      <Box component={"section"} id="project-list">
        <Container maxWidth="md">
          <Typography
            sx={{
              display: { sm: "block", md: "none" },
              textAlign: "right",
              color: "rgba(0,0,0,0.5)",
              mb: 2,
            }}
          >
            Click on menu for more!
          </Typography>
          <Grid container spacing={2}>
            {projects
              .filter((a) => a.group === pageName.toLowerCase())
              .map((i, iKey) => (
                <Grid item xs={12} key={iKey}>
                  <Paper
                    elevation={4}
                    className="project-card"
                    component={Link}
                    to={i.down ? "" : i.website}
                    target={i.down ? "" : "_blank"}
                    rel="noreferrer"
                    onClick={() => {
                      if (i.down) {
                        setSnackbar((snackbar) => ({
                          ...snackbar,
                          open: true,
                          text: i.maintenance,
                          severity: "info",
                        }));
                      }
                    }}
                  >
                    <Box
                      component={"img"}
                      src={i.thumbnail}
                      alt={i.name}
                      className="project-thumbnail"
                    />
                    <Box className="project-contents">
                      <Typography className="project-name">{i.name}</Typography>
                      <Typography className="project-description">
                        {i.description}
                      </Typography>
                      <Typography>
                        <LanguageIcon
                          sx={{ position: "absolute", zIndex: "1000" }}
                        />{" "}
                        <Box
                          component={"span"}
                          sx={{ pl: 4, fontSize: "0.8rem" }}
                        >
                          {i.website}
                        </Box>
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        </Container>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: snackbar.y, horizontal: snackbar.x }}
        autoHideDuration={snackbar.duration}
        open={snackbar.open}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.text}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default Homepage;
