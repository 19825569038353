import { Link } from "react-router-dom";

import { Box, Button, Container, Typography } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";

import "./Footer.scss";

function Footer() {
  return (
    <Box
      component={"section"}
      id="footer"
      sx={{
        borderTop: "1px solid rgba(0,0,0,0.5)",
        py: "16px",
      }}
    >
      <Container maxWidth="sm">
        <Box className="link-holder">
          <Button
            variant="text"
            target="_blank"
            component={Link}
            to="https://www.linkedin.com/in/tony-tripulca/"
            startIcon={<LinkedInIcon />}
          >
            Linkedin
          </Button>
          <Button
            variant="text"
            target="_blank"
            component={Link}
            to="https://github.com/tony-tripulca"
            startIcon={<GitHubIcon />}
          >
            Github
          </Button>
        </Box>
        <Typography sx={{ textAlign: "center" }}>
          <a href="mailto:tony.tripulca@gmail.com">tony.tripulca@gmail.com</a>
        </Typography>
        <Typography sx={{ textAlign: "center" }}>
          A decade in the web development
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
