import React from "react";
import { BrowserRouter } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material";

import "@fontsource/ubuntu/300.css";
import "@fontsource/ubuntu/400.css";
import "@fontsource/ubuntu/500.css";
import "@fontsource/ubuntu/700.css";
import "@fontsource/monofett"; // 400
import "@fontsource-variable/outfit"; // Supports weights 100-900

import "./App.scss";

import AppRouter from "./AppRouter";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { grey } from "@mui/material/colors";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <ThemeProvider theme={customTheme()}>
          <Header />
          <AppRouter />
          <Footer />
        </ThemeProvider>
      </BrowserRouter>
    </React.Fragment>
  );
}

function customTheme() {
  return createTheme({
    typography: {
      fontFamily: `'Outfit Variable', Ubuntu, Poppins, Quicksand, Roboto, Helvetica, Arial, sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400,
      },
    },
    palette: {
      primary: {
        light: grey[700],
        main: grey[800],
        dark: grey[900],
        contrastText: "#fff",
      },
      secondary: {
        light: grey[50],
        main: grey[100],
        dark: grey[200],
        contrastText: "#000",
      },
    },
  });
}

export default App;
